<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <merchant-product-form-component
                :merchant_product_model="merchant_product_model"
                :cat_prod="cat_prod"
                :merchant_prod="merchant_prod"
                :status_list="status_list"
                update="1"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import MerchantProductFormComponent from '../../../components/page/merchant-products/MerchantProductFormComponent';
import { MerchantProductData, StatusList } from '../../../store/services/MerchantProductModel';
import { PutRequest, GetRequest } from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    MerchantProductFormComponent,
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Update Product'},
    valid: true,
    absolute: false,
    overlay: false,
    merchant_product_model: new MerchantProductData(),
    cat_prod: [],
    merchant_prod: [],
    status_list: new StatusList(),
    isAdmin: false,
  }),
  async mounted() {
    console.log('category update view');
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.currentUser.role.level === 1) {
        this.isAdmin = true;
      }
      await this.getMerProductData();
      this.cat_prod = await this.getCatData();
      this.merchant_prod = await this.getMerchantData();
    }
  },
  methods: {
    async getMerProductData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('merchant-products/' + this.$route.params._id);
        delete response.data._id;
        delete response.data.created_at;
        delete response.data.created_by;
        delete response.data.updated_at;
        delete response.data.updated_by;
        delete response.data.__v
        this.merchant_product_model = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get user data failed ' + e.message);
      }
    },
    async getCatData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('categories');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async getMerchantData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('merchants');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async saveData() {
      delete this.merchant_product_model.category?.__v;
      delete this.merchant_product_model.merchant?.__v;
      this.merchant_product_model.up_selling_price = Number(this.merchant_product_model.up_selling_price);
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await PutRequest('merchant-products/' + this.$route.params._id, this.merchant_product_model);
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        if (response.data && response.message === 'updated') {
          alert('success to updated data');
          await this.$router.push({name: 'MerchantProductList'});
        } else {
          alert('failed to updated data');
        }
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get data failed ' + e.message);
      }
    },
  }
}
</script>

<style scoped>

</style>
